import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { ChangeDetectorRef, EventEmitter, QueryList, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EntityQuery, EntityState, FilterQueryOp, Predicate } from '@cime/breeze-client';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { ExportService } from '@common/services/export.service';
import { RememberStateService } from '@common/services/remember-state.service';
import { SharedService } from '@common/services/shared.service';
import { environment } from '@environments/environment';
import { ColumnBase, ColumnComponent, CommandColumnComponent, DetailTemplateDirective, GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/export.service";
import * as i3 from "@angular/router";
import * as i4 from "@common/services/remember-state.service";
import * as i5 from "@common/services/shared.service";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@progress/kendo-angular-grid";
function AppGridComponent_3_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 5);
    i0.ɵɵlistener("click", function AppGridComponent_3_ng_template_0_div_0_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext(3);
      const _r0 = i0.ɵɵreference(1);
      return i0.ɵɵresetView(ctx_r5.expandDetails(_r0));
    });
    i0.ɵɵtext(2, " Expand all ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 5);
    i0.ɵɵlistener("click", function AppGridComponent_3_ng_template_0_div_0_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r7 = i0.ɵɵnextContext(3);
      const _r0 = i0.ɵɵreference(1);
      return i0.ɵɵresetView(ctx_r7.collapseDetails(_r0));
    });
    i0.ɵɵtext(4, " Collapse all ");
    i0.ɵɵelementEnd()();
  }
}
function AppGridComponent_3_ng_template_0_button_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵtext(1, " Add ");
    i0.ɵɵelementEnd();
  }
}
function AppGridComponent_3_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AppGridComponent_3_ng_template_0_div_0_Template, 5, 0, "div", 2);
    i0.ɵɵtemplate(1, AppGridComponent_3_ng_template_0_button_1_Template, 2, 0, "button", 4);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !!ctx_r2.detailTemplate && ctx_r2.dataLength > 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.editMode && ctx_r2.canAddNew);
  }
}
function AppGridComponent_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AppGridComponent_3_ng_template_0_Template, 2, 2, "ng-template", 3);
  }
}
export class AppGridComponent {
  get isBusy() {
    return this._isBusy;
  }
  set isBusy(value) {
    if (this._isBusy !== value) {
      this._isBusy = value;
      this.sharedService.sendData(value);
      this.isBusyChange.emit(value);
    }
  }
  get pageSizes() {
    return environment.settings.grid.pageable.pageSizes;
  }
  get detailTemplate() {
    if (this._customDetailTemplate) return this._customDetailTemplate;
    return this.detailTemplateChildren?.first || undefined;
  }
  set detailTemplate(detailTemplate) {
    this._customDetailTemplate = detailTemplate;
  }
  get fields() {
    return this.columns.filter(x => x instanceof ColumnComponent).map(x => x.field).filter(x => x);
  }
  get dataLength() {
    return this.data?.length || this.data?.total;
  }
  constructor(changeDetectorRef, breezeViewService, exportService, router, rememberStateService, sharedService) {
    this.changeDetectorRef = changeDetectorRef;
    this.breezeViewService = breezeViewService;
    this.exportService = exportService;
    this.router = router;
    this.rememberStateService = rememberStateService;
    this.sharedService = sharedService;
    this.id = _.uniqueId('app-grid-');
    this.detailedExcelExport = false;
    this.destroy$ = new Subject();
    this.rendered = false;
    this.deferredColumns = [];
    this._isBusy = false;
    this.unsubscribe = _.noop;
    this.state = {
      skip: 0,
      take: environment.settings.grid.pageSize
    };
    this.stateUrlParams = false;
    this.columns = new QueryList();
    this.sortable = environment.settings.grid.sortable;
    this.sort = [...environment.settings.grid.sort];
    this.selection = [];
    this.selectBy = 'id';
    this.pageSize = environment.settings.grid.pageSize;
    this.pageable = environment.settings.grid.pageable;
    this.pagerPosition = 'bottom';
    this.scrollable = 'none';
    this.exportFileName = 'Export';
    this.rowClass = () => {};
    this.stateChange = new EventEmitter();
    this.selectionChange = new EventEmitter();
    this.isBusyChange = new EventEmitter();
    this.sortChange = new EventEmitter();
    this.expandedDetailIndexes = [];
    this.entityManager = breezeViewService.entityManager;
    this.activatedRoute = breezeViewService.activatedRoute;
    if (this.router.url.includes('/list')) this.initializeState();
  }
  ngOnInit() {
    this.initializeState();
    this.state.sort = this.sort;
    if (!this.stateUrlParams) this.state.take = this.pageSize;
    // Angular change detection workaround
    setTimeout(() => {
      if (this.pageable) this.pageable.position = this.pagerPosition;
    });
    this.initializeDetailsExpansionState();
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.unsubscribe();
  }
  ngOnChanges(changes) {
    if (!changes.data) return;
    if (changes.sort) this.state.sort = this.sort;
    if (!this.stateUrlParams) this.state.skip = 0;
    if (_.isFunction(this.data)) {
      this.onDataStateChange = this.functionDataStateChanged;
    } else if (_.isArray(this.data)) {
      const array = this.data;
      this.onDataStateChange = state => {
        this.arrayDataStateChanged(array, state);
      };
      this.unsubscribe();
      if (this.data.arrayChanged) {
        this._sub = this.data.arrayChanged.subscribe(() => {
          this.onDataStateChange(this.state);
        });
        this.unsubscribe = () => {
          array.arrayChanged.unsubscribe(this._sub);
          this.unsubscribe = _.noop;
        };
      }
    } else if (this.data instanceof EntityQuery) {
      const query = this.data;
      this.onDataStateChange = state => {
        this.queryDataStateChanged(query, state);
      };
    }
    if (this.data) {
      this.onDataStateChange();
    } else {
      this.internalData = [];
    }
  }
  ngAfterViewInit() {
    this.rendered = true;
    this.grid.detailTemplate = this.detailTemplate;
    _.each(this.deferredColumns, args => this.addColumn(args.column, args.index));
    this.deferredColumns.length = 0;
    this.updateColumns();
    this.columns.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateColumns();
    });
  }
  search() {
    this.onDataStateChange();
  }
  initializeState(state) {
    if (state) {
      this.state = state;
      return;
    }
    const urlStateParams = this.destructureUrlStateParams();
    if (urlStateParams.skip !== undefined && urlStateParams.take !== undefined) {
      this.state = urlStateParams;
    }
  }
  addColumn(column, index) {
    if (!this.rendered) {
      this.deferredColumns.push({
        column,
        index
      });
      return;
    }
    const columns = this.columns.toArray();
    if (index === undefined) {
      columns.push(column);
    } else {
      columns.splice(index, 0, column);
    }
    this.columns.reset(columns);
  }
  updateColumns() {
    this.grid.columns.reset(this.columns.toArray());
    this.changeDetectorRef.detectChanges();
  }
  arrayDataStateChanged(array, state) {
    if (state) {
      this.state = state;
      this.stateChange.emit(state);
    }
    try {
      this.isBusy = true;
      this.internalData = process(array, {
        ...this.state,
        take: !this.pageable ? null : this.state.take
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
  functionDataStateChanged(state) {
    const result = this.data(state);
    if (_.isArray(result)) {
      this.arrayDataStateChanged(result, state);
    } else if (result instanceof EntityQuery) {
      this.queryDataStateChanged(result, state);
    } else {
      throw new Error(`Unsupported data type: ${typeof result}`);
    }
  }
  queryDataStateChanged(query, state) {
    var _this = this;
    return _asyncToGenerator(function* () {
      if (state) {
        _this.state = state;
        _this.stateChange.emit(state);
      }
      try {
        _this.isBusy = true;
        if (_this.pageable) {
          query = query.inlineCount(true);
          query = query.skip(_this.state.skip);
          query = query.take(_this.state.take);
        }
        if (_this.state.sort && _this.state.sort.filter(x => x.dir).length > 0) {
          query = query.orderBy(_this.state.sort.filter(x => x.dir).map(x => `${x.field} ${x.dir}`).join(', '));
        }
        const predicate = _this.getCompositeFilterPredicate(_this.state.filter);
        if (predicate) {
          query = query.where(predicate);
        }
        yield _this.entityManager.executeQuery(query).then(response => {
          _this.internalData = {
            total: response.inlineCount,
            data: response.results.filter(x => x.entityAspect?.entityState !== EntityState.Deleted)
          };
          _this.isBusy = false;
        }).catch(e => {
          console.error(e);
          _this.isBusy = false;
        });
      } catch (e) {
        console.error(e);
        _this.isBusy = false;
      }
    })();
  }
  getCompositeFilterPredicate(filter) {
    if (!filter) return null;
    const predicates = filter.filters.map(x => x.filters ? this.getCompositeFilterPredicate(x) : this.getFilterPredicate(x));
    if (predicates.length === 0) {
      return null;
    } else if (predicates.length === 1) {
      return predicates[0];
    } else {
      const logic = filter.logic === 'and' ? Predicate.and : Predicate.or;
      return logic(predicates);
    }
  }
  getFilterPredicate({
    field,
    operator,
    value
  }) {
    switch (operator) {
      case 'isnotnull':
        // NotNull
        return Predicate.create(field, FilterQueryOp.NotEquals, null);
      case 'isnull':
        // IsNull
        return Predicate.create(field, FilterQueryOp.Equals, null);
      case 'eq':
        // Equal
        return Predicate.create(field, FilterQueryOp.Equals, value);
      case 'neq':
        // NotEqual
        return Predicate.create(field, FilterQueryOp.NotEquals, value);
      case 'lt':
        // LessThan
        return Predicate.create(field, FilterQueryOp.LessThan, value);
      case 'lte':
        // LessOrEqual
        return Predicate.create(field, FilterQueryOp.LessThanOrEqual, value);
      case 'gt':
        // GreaterThan
        return Predicate.create(field, FilterQueryOp.GreaterThan, value);
      case 'gte':
        // GreaterOrEqual
        return Predicate.create(field, FilterQueryOp.GreaterThanOrEqual, value);
      case 'contains':
        // Contains
        return Predicate.create(field, FilterQueryOp.Contains, value);
      case 'doesnotcontain':
        // NotContain
        return Predicate.not(Predicate.create(field, FilterQueryOp.Contains, value));
      case 'startswith':
        // StartsWith
        return Predicate.create(field, FilterQueryOp.StartsWith, value);
      case 'endswith':
        // EndsWith
        return Predicate.create(field, FilterQueryOp.EndsWith, value);
      case 'isempty':
        return Predicate.create(field, FilterQueryOp.Equals, '');
      case 'isnotempty':
        return Predicate.create(field, FilterQueryOp.NotEquals, '');
      default:
        throw new Error(`Unknown operator for remoteFilter - ${operator}`);
    }
  }
  onSelectionChange(event) {
    this.selectionChange.emit(this.selection);
  }
  // Add skip and take to url parameters
  onPageChange(event = null) {
    if (!this.router.url.includes('/list')) return;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: event ? {
        skip: event.skip,
        take: event.take
      } : {},
      queryParamsHandling: event ? 'merge' : '',
      replaceUrl: true
    });
  }
  // Destructure skip and take url parameters
  destructureUrlStateParams() {
    this.stateUrlParams = this.router.routerState.snapshot.root.queryParams.skip !== undefined || this.router.routerState.snapshot.root.queryParams.take !== undefined;
    if (!this.stateUrlParams) return {};
    return {
      skip: +this.router.routerState.snapshot.root.queryParams.skip,
      take: +this.router.routerState.snapshot.root.queryParams.take
    };
  }
  getGrid() {
    return this.grid;
  }
  transformExcelExport(args) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      args.preventDefault();
      _this2.exportService.transformExcelExport(args.workbook, _this2.internalData.data, _this2.entityName, _this2.exportFileName, _this2.detailedExcelExport);
    })();
  }
  onDetailExpand(event) {
    this.expandedDetailIndexes.push(event.index);
    this.setDetailExpansionState();
  }
  onDetailCollapse(event) {
    this.expandedDetailIndexes.splice(this.expandedDetailIndexes.indexOf(event.index), 1);
    this.setDetailExpansionState();
  }
  setDetailExpansionState() {
    this.rememberStateService.setState(`${this.router.url}/${this.entityName}`, this.expandedDetailIndexes);
  }
  initializeDetailsExpansionState() {
    this.expandedDetailIndexes = this.rememberStateService.initialize(`${this.router.url}/${this.entityName}`) || [];
    if (this.expandedDetailIndexes) {
      // Angular change detection workaround
      setTimeout(() => {
        this.expandDetails(this.grid, this.expandedDetailIndexes, true);
      });
    }
  }
  expandDetails(sender, detailIndexes = null, initializing = false) {
    sender.data?.data?.forEach((item, index) => {
      if (!detailIndexes || detailIndexes.includes(index)) sender.expandRow(index);
    });
    this.expandedDetailIndexes = detailIndexes || sender.data.data.map((item, index) => index);
    if (!initializing) this.setDetailExpansionState();
  }
  collapseDetails(sender, detailIndexes = null) {
    sender.data.data?.forEach((item, index) => {
      if (!detailIndexes || detailIndexes.includes(index)) sender.collapseRow(index);
    });
    this.expandedDetailIndexes = [];
    this.setDetailExpansionState();
  }
  onSortChange(sort) {
    this.sortChange.emit(sort);
  }
}
AppGridComponent.ɵfac = function AppGridComponent_Factory(t) {
  return new (t || AppGridComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.ExportService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i4.RememberStateService), i0.ɵɵdirectiveInject(i5.SharedService));
};
AppGridComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AppGridComponent,
  selectors: [["app-grid"]],
  contentQueries: function AppGridComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnBase, 4);
      i0.ɵɵcontentQuery(dirIndex, DetailTemplateDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.columns = _t);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.detailTemplateChildren = _t);
    }
  },
  viewQuery: function AppGridComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(GridComponent, 5);
      i0.ɵɵviewQuery(CommandColumnComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.grid = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.commandColumns = _t);
    }
  },
  inputs: {
    data: "data",
    sortable: "sortable",
    sort: "sort",
    selectable: "selectable",
    selection: "selection",
    selectBy: "selectBy",
    filterable: "filterable",
    groupable: "groupable",
    pageSize: "pageSize",
    pageable: "pageable",
    pagerPosition: "pagerPosition",
    scrollable: "scrollable",
    height: "height",
    entityName: "entityName",
    exportFileName: "exportFileName",
    rowClass: "rowClass"
  },
  outputs: {
    stateChange: "stateChange",
    selectionChange: "selectionChange",
    isBusyChange: "isBusyChange",
    sortChange: "sortChange"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 4,
  vars: 18,
  consts: [[3, "id", "data", "loading", "skip", "pageable", "pageSize", "sortable", "sort", "scrollable", "filterable", "filter", "groupable", "selectable", "kendoGridSelectBy", "height", "rowClass", "selectedKeys", "selectedKeysChange", "selectionChange", "selectAllChange", "pageChange", "dataStateChange", "sortChange", "excelExport", "detailExpand", "detailCollapse"], ["grid", ""], [4, "ngIf"], ["kendoGridToolbarTemplate", "", "position", "bottom"], ["class", "btn btn-sm btn-primary float-right", "type", "button", "kendoGridAddCommand", "", "translate", "", 4, "ngIf"], ["type", "button", "translate", "", 1, "btn", "btn-sm", "btn-primary", 3, "click"], ["type", "button", "kendoGridAddCommand", "", "translate", "", 1, "btn", "btn-sm", "btn-primary", "float-right"]],
  template: function AppGridComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-grid", 0, 1);
      i0.ɵɵlistener("selectedKeysChange", function AppGridComponent_Template_kendo_grid_selectedKeysChange_0_listener($event) {
        return ctx.selection = $event;
      })("selectionChange", function AppGridComponent_Template_kendo_grid_selectionChange_0_listener($event) {
        return ctx.onSelectionChange($event);
      })("selectAllChange", function AppGridComponent_Template_kendo_grid_selectAllChange_0_listener($event) {
        return ctx.onSelectionChange($event);
      })("pageChange", function AppGridComponent_Template_kendo_grid_pageChange_0_listener($event) {
        return ctx.onPageChange($event);
      })("dataStateChange", function AppGridComponent_Template_kendo_grid_dataStateChange_0_listener($event) {
        return ctx.onDataStateChange($event);
      })("sortChange", function AppGridComponent_Template_kendo_grid_sortChange_0_listener($event) {
        return ctx.onSortChange($event);
      })("excelExport", function AppGridComponent_Template_kendo_grid_excelExport_0_listener($event) {
        return ctx.transformExcelExport($event);
      })("detailExpand", function AppGridComponent_Template_kendo_grid_detailExpand_0_listener($event) {
        return ctx.onDetailExpand($event);
      })("detailCollapse", function AppGridComponent_Template_kendo_grid_detailCollapse_0_listener($event) {
        return ctx.onDetailCollapse($event);
      });
      i0.ɵɵelement(2, "kendo-grid-excel");
      i0.ɵɵtemplate(3, AppGridComponent_3_Template, 1, 0, null, 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵpropertyInterpolate("id", ctx.id);
      i0.ɵɵproperty("data", ctx.internalData)("loading", ctx.isBusy)("skip", ctx.state.skip)("pageable", ctx.pageable)("pageSize", ctx.state.take)("sortable", ctx.sortable)("sort", ctx.state.sort)("scrollable", ctx.scrollable)("filterable", ctx.filterable)("filter", ctx.state.filter)("groupable", ctx.groupable)("selectable", ctx.selectable)("kendoGridSelectBy", ctx.selectBy)("height", ctx.height)("rowClass", ctx.rowClass)("selectedKeys", ctx.selection);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !!ctx.detailTemplate && ctx.dataLength > 0);
    }
  },
  dependencies: [i6.NgIf, i7.TranslateDirective, i8.GridComponent, i8.ToolbarTemplateDirective, i8.SelectionDirective, i8.AddCommandDirective, i8.ExcelComponent],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});