import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { CodelistPipe } from '@common/pipes/codelist.pipe';
import { PadPipe } from '@common/pipes/pad.pipe';
import { BreezeService } from '@common/services/breeze.service';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze.service";
import * as i2 from "@common/pipes/codelist.pipe";
export class ExportService {
  constructor(breezeService, codelist) {
    this.breezeService = breezeService;
    this.codelist = codelist;
    this.pad = new PadPipe();
    this.badCodelistKeys = [];
  }
  transformExcelExport(workbook, data, entityName, exportFileName = 'Export.xlsx', detailedExcelExport) {
    var _this = this;
    return _asyncToGenerator(function* () {
      const book = workbook;
      const sheet = book.sheets[0];
      const rows = sheet.rows;
      const columns = sheet.columns;
      const headerOptions = rows[0].cells[0];
      const thousandsOptions = {
        format: '#,0.00'
      };
      const weightOptions = {
        format: '0,0.000'
      };
      const dtoGridRows = [];
      data.forEach(element => {
        dtoGridRows.push(_this.breezeService.convertToDto(element));
      });
      if (!detailedExcelExport) {
        for (let i = 1; i < rows.length; i++) {
          for (let j = 0; j < rows[1].cells.length; j++) {
            const cell = rows[i].cells[j];
            const codelistKeys = _this.isCodeList(cell.value, dtoGridRows[i - 1]) ?? [rows[0].cells[j].value.replaceAll(' ', '')];
            const columnTitle = rows[0].cells[j].value;
            if (columnTitle !== 'ID') yield _this.formatCell(cell, codelistKeys, entityName);
          }
        }
      } else {
        exportFileName = `Detailed${exportFileName}`;
        if (entityName === 'Invoice') {
          // Remove these columns
          const filterOut = ['Vessel Name', 'Vessel Visit', 'ATA'];
          const spliceIndexes = [];
          for (let i = rows[0].cells.length - 1; i >= 0; i--) {
            if (filterOut.includes(rows[0].cells[i].value)) {
              spliceIndexes.push(i);
            }
          }
          spliceIndexes.forEach(index => {
            rows.forEach(rows => {
              rows.cells.splice(index, 1);
            });
          });
          for (let i = rows.length - 1; i >= 1; i--) {
            const visit = data[i - 1]?.vesselVisit ?? data[i - 1]?.vtsVesselVisit;
            const dtoVisit = _this.breezeService.convertToDto(visit);
            const dtoVessel = _this.breezeService.convertToDto(data[i - 1].vesselRevision);
            const dtoItems = [];
            data[i - 1].items.forEach(element => {
              dtoItems.push(_this.breezeService.convertToDto(element));
            });
            // Invoice items
            for (let k = dtoItems.length - 1; k >= 0; k--) {
              const dtoItemCells = [];
              dtoItemCells.push({}, {
                value: yield _this.codelist.transform(dtoItems[k].serviceId, 'Service')
              }, {
                value: dtoItems[k].description
              }, {
                value: dtoItems[k].quantity
              }, {
                value: yield _this.codelist.transform(dtoItems[k].unitOfMeasurementId, 'UnitOfMeasurement')
              }, Object.assign({}, thousandsOptions, {
                value: dtoItems[k].pricePerUnit
              }), Object.assign({}, thousandsOptions, {
                value: dtoItems[k].taxAmount
              }), Object.assign({}, thousandsOptions, {
                value: dtoItems[k].amount
              }), Object.assign({}, thousandsOptions, {
                value: dtoItems[k].totalAmount
              }));
              rows.splice(i + 1, 0, {
                type: 'data',
                cells: dtoItemCells
              });
            }
            const dtoItemHeaderCells = [];
            dtoItemHeaderCells.push({}, Object.assign({}, headerOptions, {
              value: 'Service'
            }), Object.assign({}, headerOptions, {
              value: 'Description'
            }), Object.assign({}, headerOptions, {
              value: 'Quantity'
            }), Object.assign({}, headerOptions, {
              value: 'Unit Of Measurement'
            }), Object.assign({}, headerOptions, {
              value: 'Price Per Unit'
            }), Object.assign({}, headerOptions, {
              value: 'VAT Amount (%)'
            }), Object.assign({}, headerOptions, {
              value: 'Amount'
            }), Object.assign({}, headerOptions, {
              value: 'TotalAmount'
            }));
            rows.splice(i + 1, 0, {
              type: 'header',
              cells: dtoItemHeaderCells
            });
            // Visit & Vessel
            const dtoVisitCells = [];
            dtoVisitCells.push({}, {
              value: data[i - 1]?.voyageNumber
            }, {
              value: _this.formatDate(dtoVisit?.ata)
            }, {
              value: _this.formatDate(dtoVisit?.atd)
            }, {
              value: yield _this.codelist.transform(dtoVisit?.portOfCallId ?? dtoVisit?.portId, 'Port')
            }, {
              value: yield _this.codelist.transform(dtoVisit?.berthId, 'Berth')
            }, {
              value: dtoVessel?.name
            }, Object.assign({}, weightOptions, {
              value: dtoVessel?.netTonnage
            }), Object.assign({}, weightOptions, {
              value: dtoVessel?.grossTonnage
            }), Object.assign({}, thousandsOptions, {
              value: dtoVessel?.length
            }), {
              value: dtoVisit?.personsOnBoard
            });
            rows.splice(i + 1, 0, {
              type: 'data',
              cells: dtoVisitCells
            });
            const dtoVisitHeaderCells = [];
            dtoVisitHeaderCells.push({}, Object.assign({}, headerOptions, {
              value: 'Visit Number'
            }), Object.assign({}, headerOptions, {
              value: 'ATA'
            }), Object.assign({}, headerOptions, {
              value: 'ATD'
            }), Object.assign({}, headerOptions, {
              value: 'Port Of Call'
            }), Object.assign({}, headerOptions, {
              value: 'Berth'
            }), Object.assign({}, headerOptions, {
              value: 'Vessel Name'
            }), Object.assign({}, headerOptions, {
              value: 'Net Tonnage'
            }), Object.assign({}, headerOptions, {
              value: 'Gross Tonnage'
            }), Object.assign({}, headerOptions, {
              value: 'Length Overall'
            }), Object.assign({}, headerOptions, {
              value: 'Persons On Board'
            }));
            rows.splice(i + 1, 0, {
              type: 'header',
              cells: dtoVisitHeaderCells
            });
            // Invoices
            for (let j = rows[1].cells.length - 1; j >= 0; j--) {
              const cell = rows[i].cells[j];
              const codelistKeys = _this.isCodeList(cell.value, dtoGridRows[i - 1]);
              const columnTitle = rows[0].cells[j].value;
              yield _this.formatCell(cell, codelistKeys, entityName);
              if (['ID', 'Number'].includes(columnTitle)) {
                cell.bold = true;
                cell.textAlign = 'left';
              }
              if (columnTitle === 'ID') {
                cell.value = _this.pad.transform(cell.value);
              }
              if (['Amount', 'Tax', 'Total Amount'].includes(columnTitle)) {
                cell.format = thousandsOptions.format;
              }
            }
            if (i > 1) {
              rows.splice(i, 0, {});
              rows.splice(i + 1, 0, {
                type: 'header',
                cells: rows[0].cells
              });
            }
          }
        }
      }
      // Override default autosize width
      if (rows.length > 1) _this.setAutoColumnWidths(rows, columns);
      new Workbook({
        creator: 'Billing Malta',
        sheets: [sheet]
      }).toDataURL().then(dataUrl => saveAs(dataUrl, `${exportFileName}.xlsx`));
    })();
  }
  formatCell(cell, codelistKeys, entityName) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      // Format codelists
      if (['number', 'string'].includes(typeof cell.value) && codelistKeys.length > 0) {
        for (const codelistKey of codelistKeys.filter(codelistKey => !_this2.badCodelistKeys.includes(codelistKey))) {
          let codelistValue = yield _this2.codelist.transform(cell.value, `${entityName}${codelistKey}`);
          if (codelistValue === '-') {
            _this2.badCodelistKeys.push(`${entityName}${codelistKey}`);
            codelistValue = yield _this2.codelist.transform(cell.value, codelistKey);
          }
          if (codelistValue !== '-') {
            cell.value = codelistValue;
            break;
          } else {
            _this2.badCodelistKeys.push(codelistKey);
          }
        }
      }
      // Format date
      if (typeof cell.value === 'object' && !!cell.value && !isNaN(Date.parse(cell.value))) {
        cell.value = _this2.formatDate(cell.value);
        cell.value = cell.value.substring(0, cell.value.length - 3);
      }
      // Format empty array
      if (typeof cell.value === 'object' && cell.value?.length === 0) {
        cell.value = '';
      }
      // Format invoices array
      if (typeof cell.value === 'object' && cell.value?.length > 0) {
        cell.value = _this2.breezeService.convertToDto(cell.value).filter(value => value.invoiceNumber).at(-1)?.invoiceNumber;
      }
      // Format boolean
      if (typeof cell.value === 'boolean') {
        cell.value = cell.value ? '  ✓  ' : '';
      }
    })();
  }
  formatDate(date) {
    if (!date) return '';
    return `${new Date(date).toLocaleDateString('en-SI')} ${new Date(date).toLocaleTimeString('en-SI')}`;
  }
  setColumnWidths(columns, width) {
    columns.forEach(column => {
      column.autoWidth = false;
      column.width = width;
    });
  }
  setAutoColumnWidths(rows, columns) {
    const allLengths = [];
    for (let i = 0; i < rows[1].cells.length; i++) {
      const lengths = [];
      for (const row of rows) {
        const cellValue = row.cells ? row.cells[i]?.value : {};
        if (typeof cellValue === 'string') {
          lengths.push(cellValue.length);
        } else {
          lengths.push(5);
        }
      }
      allLengths.push(lengths);
    }
    const maxLengths = [];
    allLengths.forEach(col => maxLengths.push(Math.max(...col)));
    for (const col in columns) {
      if (!col) continue;
      columns[col].autoWidth = false;
      columns[col].width = maxLengths[col] * 9;
    }
  }
  isCodeList(cellValue, gridRow) {
    // Only checks one level
    for (const [key, value] of Object.entries(gridRow)) {
      if (['number', 'string'].includes(typeof value) && value === cellValue && key.substring(key.length - 2) === 'Id' && key.length > 2) {
        const keys = key.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).filter(key => key.length > 2).map(key => key.charAt(0).toUpperCase() + key.slice(1));
        const combinedKeys = [];
        // combine words in a forward direction
        for (let i = 0; i < keys.length; i++) {
          combinedKeys.push(keys[i]);
          for (let j = i + 1; i < keys.length - 1; j++) {
            if (!keys[j]) break;
            combinedKeys.push(`${keys[i]}${keys[j]}`);
          }
        }
        return combinedKeys;
      }
    }
    return [];
  }
}
ExportService.ɵfac = function ExportService_Factory(t) {
  return new (t || ExportService)(i0.ɵɵinject(i1.BreezeService), i0.ɵɵinject(i2.CodelistPipe));
};
ExportService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: ExportService,
  factory: ExportService.ɵfac,
  providedIn: 'root'
});