import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { PriceListStatusCodes, PriceListTypeCodes } from '@common/classes/codes';
import { PriceListPermissions } from '@common/classes/permissions';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { PriceListService } from '@price-list/services/price-list.service';
import { addYears, startOfDay } from 'date-fns';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@progress/kendo-angular-layout";
import * as i5 from "../../../common/components/action-bar/action-bar.component";
import * as i6 from "../../../common/components/app-control/app-control.component";
import * as i7 from "../../../common/components/loader/loader.component";
import * as i8 from "../../../common/components/content-header/content-header.component";
import * as i9 from "../../../common/components/note-list/note-list.component";
import * as i10 from "../audit-view/audit-view.component";
import * as i11 from "../exemption-list/exemption-list.component";
import * as i12 from "../due-item-list/due-item-list.component";
import * as i13 from "../charging-conditions-view/charging-conditions-view.component";
import * as i14 from "@ngx-translate/core";
const _c0 = ["files"];
const _c1 = ["tabStrip"];
function PriceListViewComponent_ng_template_1_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8)(1, "div", 9)(2, "div", 10)(3, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function PriceListViewComponent_ng_template_1_ng_template_6_Template_app_control_ngModelChange_3_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r8.model.organizationUnitId = $event);
    });
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(5, "div", 10)(6, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function PriceListViewComponent_ng_template_1_ng_template_6_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r10.model.projectId = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 10)(9, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function PriceListViewComponent_ng_template_1_ng_template_6_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r11 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r11.model.priceListTypeId = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 10)(12, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function PriceListViewComponent_ng_template_1_ng_template_6_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r12 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r12.model.validFrom = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 10)(15, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function PriceListViewComponent_ng_template_1_ng_template_6_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r13 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r13.model.validTo = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(17, "div", 16);
    i0.ɵɵelement(18, "app-audit-view", 17);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(4, 16, "Organization Unit"));
    i0.ɵɵproperty("ngModel", ctx_r2.model.organizationUnitId)("entity", ctx_r2.model);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(7, 18, "Price List Name"));
    i0.ɵɵproperty("ngModel", ctx_r2.model.projectId)("entity", ctx_r2.model);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(10, 20, "Price List Type"));
    i0.ɵɵproperty("ngModel", ctx_r2.model.priceListTypeId)("entity", ctx_r2.model);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(13, 22, "Valid From"));
    i0.ɵɵproperty("ngModel", ctx_r2.model.validFrom)("entity", ctx_r2.model);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(16, 24, "Valid To"));
    i0.ɵɵproperty("ngModel", ctx_r2.model.validTo)("entity", ctx_r2.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("model", ctx_r2.model);
  }
}
const _c2 = function (a0, a1, a2) {
  return {
    startingEvents: a0,
    completingEvents: a1,
    berths: a2
  };
};
function PriceListViewComponent_ng_template_1_kendo_tabstrip_tab_7_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-charging-conditions-view", 18);
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("data", i0.ɵɵpureFunction3(3, _c2, ctx_r14.model.startingEvents, ctx_r14.model.completingEvents, ctx_r14.model.berths))("priceListTypeId", ctx_r14.model.priceListTypeId)("editMode", ctx_r14.editMode);
  }
}
function PriceListViewComponent_ng_template_1_kendo_tabstrip_tab_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-tabstrip-tab", 5);
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵtemplate(2, PriceListViewComponent_ng_template_1_kendo_tabstrip_tab_7_ng_template_2_Template, 1, 7, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 2, "Charging Conditions"))("selected", ctx_r3.activeTab === 1);
  }
}
function PriceListViewComponent_ng_template_1_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-due-item-list", 19);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r4.model.dueItems)("priceListTypeId", ctx_r4.model.priceListTypeId)("editMode", ctx_r4.editMode);
  }
}
function PriceListViewComponent_ng_template_1_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-exemption-list", 20);
    i0.ɵɵlistener("selectionChange", function PriceListViewComponent_ng_template_1_ng_template_12_Template_app_exemption_list_selectionChange_0_listener($event) {
      i0.ɵɵrestoreView(_r16);
      const ctx_r15 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r15.selectionChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r5.model.exemptionItems)("selection", ctx_r5.exemptionItemSelection)("editMode", ctx_r5.editMode);
  }
}
function PriceListViewComponent_ng_template_1_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 21, 22);
    i0.ɵɵlistener("ngModelChange", function PriceListViewComponent_ng_template_1_ng_template_15_Template_app_control_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const ctx_r18 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r18.model.attachments = $event);
    })("addFile", function PriceListViewComponent_ng_template_1_ng_template_15_Template_app_control_addFile_0_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const ctx_r20 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r20.onAddFile($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("multiple", true)("isDisabled", !ctx_r6.editMode)("ngModel", ctx_r6.model.attachments)("entity", ctx_r6.model);
  }
}
function PriceListViewComponent_ng_template_1_ng_template_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-note-list", 23);
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r7.model.notes)("editMode", ctx_r7.editMode);
  }
}
function PriceListViewComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r22 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵelementStart(2, "kendo-tabstrip", 3, 4);
    i0.ɵɵlistener("tabSelect", function PriceListViewComponent_ng_template_1_Template_kendo_tabstrip_tabSelect_2_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.tabSelected($event));
    });
    i0.ɵɵelementStart(4, "kendo-tabstrip-tab", 5);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵtemplate(6, PriceListViewComponent_ng_template_1_ng_template_6_Template, 19, 26, "ng-template", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, PriceListViewComponent_ng_template_1_kendo_tabstrip_tab_7_Template, 3, 4, "kendo-tabstrip-tab", 7);
    i0.ɵɵelementStart(8, "kendo-tabstrip-tab", 5);
    i0.ɵɵtemplate(9, PriceListViewComponent_ng_template_1_ng_template_9_Template, 1, 3, "ng-template", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "kendo-tabstrip-tab", 5);
    i0.ɵɵpipe(11, "translate");
    i0.ɵɵtemplate(12, PriceListViewComponent_ng_template_1_ng_template_12_Template, 1, 3, "ng-template", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "kendo-tabstrip-tab", 5);
    i0.ɵɵpipe(14, "translate");
    i0.ɵɵtemplate(15, PriceListViewComponent_ng_template_1_ng_template_15_Template, 2, 4, "ng-template", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "kendo-tabstrip-tab", 5);
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵtemplate(18, PriceListViewComponent_ng_template_1_ng_template_18_Template, 1, 2, "ng-template", 6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", ctx_r0.title);
    i0.ɵɵproperty("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(5, 14, "Payer Basic Information"))("selected", ctx_r0.activeTab === 0);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.showChargingConditions);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.itemsTitle)("selected", ctx_r0.activeTab === 2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(11, 16, "Exemptions") + " (" + ctx_r0.countActive(ctx_r0.model.exemptionItems) + ")")("selected", ctx_r0.activeTab === 3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(14, 18, "Attachments") + " (" + ctx_r0.model.attachments.length + ")")("selected", ctx_r0.activeTab === 4);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(17, 20, "Notes") + " (" + ctx_r0.countActive(ctx_r0.model.notes) + ")")("selected", ctx_r0.activeTab === 5);
  }
}
export class PriceListViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.parentRoute = '/price-list';
    this.entityName = 'PriceList';
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Price Lists'),
      route: '/price-list/list'
    }, {
      icon: 'edit',
      title: this.translateService.get('Price List')
    }];
    this.exemptionItemSelection = [];
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Clone'),
      icon: 'clone',
      isVisible: () => this.viewMode,
      onClick: () => this.breezeViewService.handleCommand('ClonePriceList', {
        priceListId: this.model.id
      }).then(clonedPriceList => {
        if (clonedPriceList) this.redirectToList();
      })
    });
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      onClick: () => super.executeAndHandle('DeletePriceLists', 'Delete'),
      permissions: [PriceListPermissions.Action.delete]
    });
    this.actionBar.push({
      label: this.translateService.instant('Workflow'),
      isVisible: () => this.viewMode,
      items: [{
        label: this.translateService.instant('Send To Archive'),
        icon: 'archive',
        onClick: () => super.executeAndHandle('ArchivePriceLists', 'Archive'),
        permissions: [PriceListPermissions.Action.archive]
      }, {
        label: this.translateService.instant('Deactivate Exemptions'),
        icon: 'toggle-off',
        isDisabled: () => this.exemptionItemSelection.length === 0,
        onClick: () => this.deactivateExemptions(),
        permissions: [PriceListPermissions.Action.edit]
      }]
    });
  }
  modelLoaded() {
    if (this.isNew) {
      this.model.statusId = PriceListStatusCodes.Active;
      this.model.validFrom = startOfDay(new Date());
      this.model.validTo = addYears(startOfDay(new Date()), 1);
    }
    this.showChargingConditions = this.model.priceListTypeId !== PriceListTypeCodes.PermitFee;
    super.modelLoaded();
  }
  get title() {
    return this.isNew ? this.translateService.instant('New Price List') : `${this.translateService.instant('Price List')} ${this.model.priceListType ? ` - ${this.model.priceListType.name}` : ''}`;
  }
  get itemsTitle() {
    let title = this.isNew ? this.translateService.instant('Items') : `${this.model.priceListType ? this.model.priceListType.name : ''} Items`;
    return title += ` (${this.model.dueItems.length})`;
  }
  deactivateExemptions() {
    var _this = this;
    return _asyncToGenerator(function* () {
      const result = yield _this.dialogService.confirm(`Deactivate Exemptions`, `You are about to deactivate selected (${_this.exemptionItemSelection.length}) Exemptions. This will prevent further Charge candidates from being generated with this Exemption for Vessel visits with ATDs hereafter. <b>This action is irreversible.</b>`);
      if (result !== true) return;
      return _this.breezeViewService.handleCommand('DeactivatePriceListExemptions', {
        ids: _this.exemptionItemSelection
      }).then(res => {
        _this.handleToastr(res);
        if (res !== true) return;
        _this.exemptionItemSelection = [];
        _this.fetch();
      });
    })();
  }
  isTariff() {
    return this.model.priceListTypeId === 'TAR';
  }
  afterSave() {
    return super.afterSave();
  }
  redirectToList() {
    this.toastrService.success(this.translateService.instant('Action successful'));
    this.router.navigate([`${this.parentRoute}/list`]);
  }
  selectionChange(selection) {
    this.exemptionItemSelection = selection;
  }
}
PriceListViewComponent.ɵfac = function PriceListViewComponent_Factory(t) {
  return new (t || PriceListViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
PriceListViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: PriceListViewComponent,
  selectors: [["app-price-list-view"]],
  viewQuery: function PriceListViewComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.files = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabStrip = _t.first);
    }
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService, PriceListService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items"], [3, "title", "breadcrumb"], [3, "tabSelect"], ["tabStrip", ""], [3, "title", "selected"], ["kendoTabContent", ""], [3, "title", "selected", 4, "ngIf"], [1, "row"], [1, "col-3"], [1, "col-12"], ["type", "codelist", "codelist", "OrganizationUnit", "property", "organizationUnitId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "string", "property", "projectId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "PriceListType", "property", "priceListTypeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "datetime", "property", "validFrom", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "datetime", "property", "validTo", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-9", "mt-3"], [3, "model"], [3, "data", "priceListTypeId", "editMode"], [3, "items", "priceListTypeId", "editMode"], [3, "items", "selection", "editMode", "selectionChange"], ["type", "file", "property", "attachments", 3, "multiple", "isDisabled", "ngModel", "entity", "ngModelChange", "addFile"], ["files", ""], [3, "items", "editMode"]],
  template: function PriceListViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, PriceListViewComponent_ng_template_1_Template, 19, 22, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.TabStripComponent, i4.TabStripTabComponent, i4.TabContentDirective, i5.ActionBarComponent, i6.AppControlComponent, i7.LoaderComponent, i8.ContentHeaderComponent, i9.NoteListComponent, i10.AuditViewComponent, i11.ExemptionListComponent, i12.DueItemListComponent, i13.ChargingConditionsViewComponent, i14.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});