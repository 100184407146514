import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { Injector, TemplateRef } from '@angular/core';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { DatePickerComponent } from '@common/components/date-picker/date-picker.component';
import { DialogFormComponent } from '@common/components/dialog-form/dialog-form.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogCloseResult, DialogService as KendoDialogService } from '@progress/kendo-angular-dialog';
import { firstValueFrom } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@progress/kendo-angular-dialog";
export class DialogService {
  constructor(modalService, kendoDialogService, injector) {
    this.modalService = modalService;
    this.kendoDialogService = kendoDialogService;
    this.injector = injector;
  }
  component(component, options, data) {
    const dialogRef = this.kendoDialogService.open({
      ...options,
      content: component
    });
    const dialogInstance = dialogRef.content.instance;
    Object.assign(dialogInstance, data);
    return firstValueFrom(dialogRef.result);
  }
  confirm(title, message, hideCancel, cancelText, confirmText) {
    var _this = this;
    return _asyncToGenerator(function* () {
      const dialogRef = _this.kendoDialogService.open({
        content: ConfirmDialogComponent,
        minWidth: 420,
        maxWidth: 720
      });
      const confirmDialog = dialogRef.content.instance;
      confirmDialog.title = title;
      confirmDialog.message = message;
      confirmDialog.hideCancel = hideCancel || confirmDialog.hideCancel;
      confirmDialog.cancelText = cancelText || confirmDialog.cancelText;
      confirmDialog.confirmText = confirmText || confirmDialog.confirmText;
      const result = yield firstValueFrom(dialogRef.result);
      return result instanceof DialogCloseResult ? false : !!result;
    })();
  }
  open(content, options, createdCallback) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      const modal = _this2.modalService.open(content, options);
      if (createdCallback) {
        createdCallback(modal);
      }
      return modal.result.catch(reason => {
        if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
          return;
        }
        throw reason;
      });
    })();
  }
  date(title, date, message = '') {
    var _this3 = this;
    return _asyncToGenerator(function* () {
      const dialogRef = _this3.kendoDialogService.open({
        content: DatePickerComponent,
        minWidth: 420
      });
      const confirmDialog = dialogRef.content.instance;
      confirmDialog.title = title;
      confirmDialog.date = date;
      confirmDialog.message = message;
      return firstValueFrom(dialogRef.result).then(result => {
        if (result?.success) {
          return result.date;
        }
        throw new Error('Cancelled');
      });
    })();
  }
  changes(title, message) {
    const dialogRef = this.modalService.open(ChangesDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.result;
  }
  warning(title, message) {
    const dialogRef = this.modalService.open(WarningDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.result;
  }
  form(options, modalOptions) {
    const dialogRef = this.modalService.open(DialogFormComponent, modalOptions);
    dialogRef.componentInstance.initialize(options, this.injector);
    return dialogRef.result.catch(reason => {
      if ([ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK].includes(reason)) return;
      throw reason;
    });
  }
}
DialogService.ɵfac = function DialogService_Factory(t) {
  return new (t || DialogService)(i0.ɵɵinject(i1.NgbModal), i0.ɵɵinject(i2.DialogService), i0.ɵɵinject(i0.Injector));
};
DialogService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: DialogService,
  factory: DialogService.ɵfac,
  providedIn: 'root'
});