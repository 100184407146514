import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { DialogService } from '@common/services/dialog.service';
import { InvoiceIssueDateComponent } from '../components/invoice-issue-date/invoice-issue-date.component';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/dialog.service";
export class InvoiceService {
  constructor(dialogService) {
    this.dialogService = dialogService;
  }
  invoiceIssueDate(params) {
    var _this = this;
    return _asyncToGenerator(function* () {
      const result = yield _this.dialogService.component(InvoiceIssueDateComponent, {
        minWidth: 500,
        minHeight: 320
      }, {
        ...params
      });
      if (result?.success) {
        return {
          date: result.date,
          serviceDate: result.serviceDate
        };
      }
      return false;
    })();
  }
}
InvoiceService.ɵfac = function InvoiceService_Factory(t) {
  return new (t || InvoiceService)(i0.ɵɵinject(i1.DialogService));
};
InvoiceService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: InvoiceService,
  factory: InvoiceService.ɵfac,
  providedIn: 'root'
});