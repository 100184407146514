import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { InvoiceListModalComponent } from '@vessel-visit/components/invoice-list-modal/invoice-list-modal.component';
export class NmswVesselVisitList extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService, modalService, dialogService) {
    super(breezeViewService, sharedService, modalService, dialogService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.modalService = modalService;
    this.dialogService = dialogService;
    this.queryName = 'VtsVesselVisits';
    this.excelExport = true;
    this.gridSort = [{
      field: 'ata',
      dir: 'asc'
    }];
    this.actionBar.shift();
  }
  latestInvoice(row) {
    return row.invoices.filter(invoice => invoice.invoiceNumber).at(-1)?.invoiceNumber;
  }
  openInvoicesModal(row) {
    const modalRef = this.breezeViewService.openModal(InvoiceListModalComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.invoices = row.invoices;
  }
  exportGridToExcel() {
    super.queryAndTriggerSaveExcel();
  }
  setBookmarkColor(entityColor) {
    var _superprop_getExecuteAndHandleSetColor = () => super.executeAndHandleSetColor,
      _this = this;
    return _asyncToGenerator(function* () {
      _superprop_getExecuteAndHandleSetColor().call(_this, _this.queryName, _this.selection?.length > 0 ? _this.selection : [entityColor.entityId], entityColor.colorId);
    })();
  }
}