import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { DataService, DataType, EntityQuery, SaveOptions } from '@cime/breeze-client';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { EntitiesErrorsComponent } from '@common/components/entities-errors/entities-errors.component';
import { ErrorDialogComponent } from '@common/components/error-dialog.component';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntitiesErrors } from './entities-errors';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
export class AbstractBreezeViewComponent {
  get title() {
    return this.mode === ViewMode.create ? `${this.translateService.instant('New')} ${this.translateService.instant(this.getFormattedTitle(this.entityName))}` : `${this.translateService.instant(this.getFormattedTitle(this.entityName))} - ${this.getIdentifier()}`;
  }
  get isNew() {
    return this.model?.entityAspect.entityState.isAdded();
  }
  getIdentifier() {
    return this.model.id;
  }
  getFormattedTitle(title) {
    return title.replace(/([A-Z])/g, ' $1').trim();
  }
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
    this.isBusy = false;
    this.redirectTo = environment.settings.view.save.redirectToViewMode;
    this.rememberTabs = true;
    this.destroy$ = new Subject();
    this.entityManager = breezeViewService.entityManager;
    this.translateService = breezeViewService.translateService;
    this.toastrService = breezeViewService.toastrService;
    this.dialogService = breezeViewService.dialogService;
    this.modalService = breezeViewService.modalService;
    this.breezeService = breezeViewService.breezeService;
    this.consoleWindowService = breezeViewService.consoleWindowService;
    this.router = breezeViewService.router;
    this.activatedRoute = breezeViewService.activatedRoute;
    this.mode = breezeViewService.activatedRoute.snapshot.data.mode;
    this.createMode = this.mode === ViewMode.create;
    this.viewMode = this.mode === ViewMode.view;
    this.editMode = !this.viewMode;
    this.activeTab = +this.router.routerState.snapshot.root.queryParams.activeTab || 0;
    breezeViewService.userService.currentUserSubject.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.user = user;
    });
    this.saveActionButton = {
      label: this.translateService.instant('Save'),
      icon: 'save',
      isDisabled: () => !this.hasChanges(),
      isVisible: () => !this.viewMode,
      onClick: () => this.saveChanges()
    };
    this.undoChangesActionButton = {
      label: this.translateService.instant('Undo Changes'),
      icon: 'undo',
      isDisabled: () => !this.hasChanges(),
      isVisible: () => !this.viewMode && this.mode !== ViewMode.create,
      onClick: () => this.rejectChanges()
    };
    this.editActionButton = {
      label: this.translateService.instant('Edit'),
      icon: 'edit',
      isDisabled: () => false,
      isVisible: () => this.canEdit() && this.viewMode,
      onClick: () => this.router.navigate([`${this.parentRoute}/edit/${this.model.id}`], this.getNavigationExtras(ViewMode.edit))
    };
    this.cancelActionButton = {
      label: this.translateService.instant('Cancel'),
      icon: 'ban',
      isDisabled: () => false,
      isVisible: () => !this.viewMode,
      onClick: () => this.cancelEdit()
    };
    this.editActionBarGroup = {
      label: this.translateService.instant('Actions'),
      items: [this.saveActionButton, this.undoChangesActionButton, this.editActionButton, this.cancelActionButton]
    };
    this.actionBar = [this.editActionBarGroup];
  }
  ngOnInit() {
    this.initialize();
  }
  initialize() {
    var _this = this;
    return _asyncToGenerator(function* () {
      _this.breezeViewService.activatedRoute.params.pipe(takeUntil(_this.destroy$)).subscribe(params => _this.id = params.id);
      if (_this.mode === ViewMode.create) {
        if (!_this.canCreateNew()) {
          _this.router.navigate([`${_this.parentRoute}/list`]);
          _this.toastrService.error('User does not have create permissions');
          return;
        }
        _this.model = yield _this.createEntity();
      } else {
        yield _this.fetch();
      }
      _this.modelLoaded();
    })();
  }
  modelLoaded() {
    if (this.mode === ViewMode.edit && !this.canEdit() && this.user) {
      this.router.navigate([`${this.parentRoute}/view/${this.id}`], this.getNavigationExtras(ViewMode.view));
      this.toastrService.error('User does not have edit permissions');
      return;
    }
  }
  getNavigationExtras(viewMode) {
    return {
      queryParamsHandling: 'merge'
    };
  }
  tabSelected(select) {
    if (!this.rememberTabs) return;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        activeTab: +select.index
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
  ngOnDestroy() {
    this.consoleWindowService.close();
    this.destroy$.next(null);
    this.destroy$.complete();
  }
  createEntity(data = {}) {
    const entityType = this.entityManager.metadataStore.getEntityType(this.entityName);
    _.chain(entityType.keyProperties).filter(o => o.dataType === DataType.String).each(o => data[o.name] = null).value();
    return this.entityManager.createEntity(this.entityName, data);
  }
  getQuery() {
    return EntityQuery.from(this.entityName).withParameters({
      id: this.id
    });
  }
  fetch(clearEntityManager = false) {
    const query = this.getQuery();
    this.isBusy = true;
    return this.entityManager.executeQuery(query).then(response => {
      this.isBusy = false;
      if (!response.results || response.results.length === 0) {
        this.toastrService.error(this.translateService.instant('Error fetching data from server'));
        return undefined;
      }
      this.model = response.results[0];
      return this.model;
    }).catch(error => {
      this.toastrService.error(this.translateService.instant('Error fetching data from server'));
      this.isBusy = false;
      throw error;
    }).finally(() => {
      if (clearEntityManager) this.entityManager.clear();
    });
  }
  getCustomcodeList(search, value, codelist, id = null) {
    const query = new EntityQuery(codelist).withParameters({
      $method: 'POST',
      $data: {
        id
      }
    });
    return this.breezeViewService.entityManager.executeQuery(query).then(data => data.results.map(x => ({
      value: x.id,
      label: x.name
    })));
  }
  executeAndHandle(command, title, body = {}, skipConfirm = false) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      const dialogResult = !skipConfirm ? _this2.dialogService.confirm(_this2.translateService.instant(title), _this2.translateService.instant(`Are you sure you want to ${title} this ${_this2.entityName}?`)) : true;
      if ((yield dialogResult) !== true) return;
      _this2.isBusy = true;
      _this2.breezeViewService.handleCommand(command, {
        ids: [_this2.model.id],
        ...body
      }).then(result => _this2.handleToastr(result)).catch(error => _this2.handleError(error)).finally(() => _this2.fetch());
    })();
  }
  handleToastr(result) {
    if (result === true) this.toastrService.success(this.translateService.instant('Action successful'));
    if (result === false) this.toastrService.error(this.translateService.instant('Action unsuccessful'));
  }
  handleError(error) {
    const dialogRef = this.modalService.open(ErrorDialogComponent, {
      size: 'lg'
    });
    dialogRef.componentInstance.isServerSideError = true;
    dialogRef.componentInstance.setMessage(error?.error.errorMessage);
  }
  handleSingleError(error) {
    const dialogRef = this.modalService.open(ErrorDialogComponent, {
      size: 'lg'
    });
    dialogRef.componentInstance.isServerSideError = true;
    dialogRef.componentInstance.setMessage(error);
  }
  rejectChanges() {
    return this.entityManager.rejectChanges();
  }
  hasChanges() {
    return this.entityManager.hasChanges();
  }
  cancelEdit() {
    if (this.mode === ViewMode.create) {
      this.router.navigate([`${this.parentRoute}/list/`]);
    } else {
      this.router.navigate([`${this.parentRoute}/view/${this.model.id}`], this.getNavigationExtras(ViewMode.view));
    }
  }
  canEdit() {
    if (this.editPermission && !this.user.isSystemUser) {
      return this.user?.hasPermission(this.editPermission);
    }
    return true;
  }
  canCreateNew() {
    if (this.createPermission && !this.user.isSystemUser) {
      return this.user?.hasPermission(this.createPermission);
    }
    return true;
  }
  getTag() {
    return undefined;
  }
  saveChanges() {
    const saveOptions = new SaveOptions({
      resourceName: `Save${this.entityName}`,
      dataService: new DataService({
        serviceName: `${environment.apiUrl}/command/`
      }),
      tag: this.getTag()
    });
    // Make sure that the root entity is always saved in order to update its version
    if (this.mode !== ViewMode.create) {
      this.model.lastModifiedDate = new Date();
    }
    const entities = this.breezeService.getEntites(this.model);
    _.forEach(entities, entity => {
      this.breezeService.clearServerValidationErrors(entity.entityAspect);
    });
    this.hideEntityErrors();
    this.beforeSave(entities);
    return this.entityManager.saveChanges(undefined, saveOptions).then(result => {
      this.toastrService.success(this.translateService.instant('Save successful'));
      this.afterSave();
      return result;
    }).catch(error => {
      const entityErrors = error?.entityErrors || _.chain(entities).map(entity => this.breezeService.getEntityErrors(entity)).flatten().value();
      if (entityErrors.length) {
        this.showEntityErrors(entityErrors);
      }
      throw error;
    });
  }
  beforeSave(input) {}
  afterSave(options) {
    if (options?.redirectToList) {
      return this.router.navigate([`${this.parentRoute}/list`]);
    }
    if (options?.redirectToViewMode !== false && this.mode !== environment.settings.view.save.redirectToViewMode) {
      return this.router.navigate([`${this.parentRoute}/${environment.settings.view.save.redirectToViewMode}/${this.model.id}`], this.getNavigationExtras(environment.settings.view.save.redirectToViewMode));
    }
  }
  showEntityErrors(entityErrors) {
    this.consoleWindowService.open(EntitiesErrorsComponent, [{
      provide: EntitiesErrors,
      useValue: new EntitiesErrors(entityErrors)
    }, {
      provide: BreezeViewService,
      useValue: this.breezeViewService
    }]);
  }
  hideEntityErrors() {
    this.consoleWindowService.close();
  }
  getEntityGraph(entity) {
    return this.entityManager.getEntityGraph(entity, this.getQuery().expandClause);
  }
  getValidationErrors(entity = this.model) {
    const graph = this.getEntityGraph(entity);
    return _.flatMap(graph, x => x.entityAspect.getValidationErrors());
  }
  isValid(entity = this.model) {
    return this.getValidationErrors(entity).length === 0;
  }
  countActive(list) {
    return list?.filter(x => x.active === undefined || x.active === true)?.length || 0;
  }
  disableAppControls(appControls) {
    appControls?._results?.forEach(appControl => appControl.isDisabled = true);
    appControls?.forEach(appControl => appControl.isDisabled = true);
  }
}
AbstractBreezeViewComponent.ɵfac = function AbstractBreezeViewComponent_Factory(t) {
  return new (t || AbstractBreezeViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
AbstractBreezeViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AbstractBreezeViewComponent,
  selectors: [["ng-component"]],
  viewQuery: function AbstractBreezeViewComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(AppControlComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appControls = _t);
    }
  },
  decls: 0,
  vars: 0,
  template: function AbstractBreezeViewComponent_Template(rf, ctx) {},
  encapsulation: 2
});