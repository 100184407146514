import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { ExportService } from '@common/services/export.service';
import { GridEditService } from '@common/services/grid-edit.service';
import { RememberStateService } from '@common/services/remember-state.service';
import { SharedService } from '@common/services/shared.service';
import { AppGridComponent } from '../app-grid/app-grid.component';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/grid-edit.service";
import * as i3 from "@common/services/export.service";
import * as i4 from "@angular/router";
import * as i5 from "@common/services/remember-state.service";
import * as i6 from "@common/services/shared.service";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "@fortawesome/angular-fontawesome";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@progress/kendo-angular-grid";
function EditableGridComponent_kendo_grid_command_column_3_ng_template_1_fa_icon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 11);
  }
}
function EditableGridComponent_kendo_grid_command_column_3_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵelement(1, "fa-icon", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 9);
    i0.ɵɵtemplate(3, EditableGridComponent_kendo_grid_command_column_3_ng_template_1_fa_icon_3_Template, 1, 0, "fa-icon", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const isNew_r5 = ctx.isNew;
    const row_r6 = ctx.$implicit;
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", !ctx_r4.canEditRow(row_r6))("primary", true);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", isNew_r5 !== undefined);
  }
}
function EditableGridComponent_kendo_grid_command_column_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-command-column", 5);
    i0.ɵɵtemplate(1, EditableGridComponent_kendo_grid_command_column_3_ng_template_1_Template, 4, 3, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("width", 37);
  }
}
function EditableGridComponent_kendo_grid_command_column_4_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵelement(1, "fa-icon", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 14);
    i0.ɵɵelement(3, "fa-icon", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", !ctx_r8.canDelete);
  }
}
function EditableGridComponent_kendo_grid_command_column_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-command-column", 5);
    i0.ɵɵtemplate(1, EditableGridComponent_kendo_grid_command_column_4_ng_template_1_Template, 4, 1, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("width", 37);
  }
}
function EditableGridComponent_5_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 18);
    i0.ɵɵlistener("click", function EditableGridComponent_5_ng_template_0_div_0_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r14);
      const ctx_r13 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r13.expandDetails(ctx_r13.grid));
    });
    i0.ɵɵtext(2, " Expand all ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 18);
    i0.ɵɵlistener("click", function EditableGridComponent_5_ng_template_0_div_0_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r14);
      const ctx_r15 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r15.collapseDetails(ctx_r15.grid));
    });
    i0.ɵɵtext(4, " Collapse all ");
    i0.ɵɵelementEnd()();
  }
}
function EditableGridComponent_5_ng_template_0_button_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 19);
    i0.ɵɵtext(1, " Add ");
    i0.ɵɵelementEnd();
  }
}
function EditableGridComponent_5_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, EditableGridComponent_5_ng_template_0_div_0_Template, 5, 0, "div", 4);
    i0.ɵɵtemplate(1, EditableGridComponent_5_ng_template_0_button_1_Template, 2, 0, "button", 17);
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !!ctx_r10.detailTemplate && ctx_r10.dataLength > 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r10.editMode && ctx_r10.canAddNew);
  }
}
function EditableGridComponent_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, EditableGridComponent_5_ng_template_0_Template, 2, 2, "ng-template", 16);
  }
}
const _c0 = function (a0) {
  return {
    compact: a0
  };
};
export class EditableGridComponent extends AppGridComponent {
  constructor(changeDetectorRef, breezeViewService, gridEdit, exportService, router, rememberStateService, sharedService) {
    super(changeDetectorRef, breezeViewService, exportService, router, rememberStateService, sharedService);
    this.gridEdit = gridEdit;
    this.canAddNew = true;
    this.canDelete = true;
    this.canEdit = true;
    this.sortable = false;
    this.sort = [];
    this.selectBy = 'id';
    this.selection = [];
    this.compact = false;
    this.canEditRow = () => true;
    this.selectionChange = new EventEmitter();
    this.rowAction = new EventEmitter();
    this.save = new EventEmitter();
    this.remove = new EventEmitter();
    this.add = new EventEmitter();
  }
  ngOnInit() {
    this.gridEdit.entityManager = this.entityManager;
    super.ngOnInit();
  }
  ngAfterViewInit() {
    this.grid.detailTemplate = this.detailTemplate;
    this.updateColumns();
  }
  updateColumns() {
    const columns = this.columns?.toArray() || [];
    if (this.editMode) {
      this.commandColumns.forEach(commandColumn => columns.push(commandColumn));
    }
    this.grid.columns.reset(columns);
    this.changeDetectorRef.detectChanges();
  }
  onSave(event) {
    this.rowAction.emit();
    this.gridEdit.saveHandler(event);
    this.save.emit(event);
  }
  onCancel(event) {
    this.gridEdit.cancelHandler(event);
    if (this.entityName === 'Attachment' && !this.gridEdit.hasAnyValues(event.dataItem)) {
      this.rowAction.emit(event);
    } else {
      this.rowAction.emit();
    }
  }
  onRemove(event) {
    this.gridEdit.removeHandler(event);
    this.rowAction.emit(event);
    this.remove.emit(event);
  }
  onAdd(event) {
    this.gridEdit.addHandler(event, this.data, this.entityName, this.createParameters);
    this.rowAction.emit(event);
    this.add.emit(event);
  }
}
EditableGridComponent.ɵfac = function EditableGridComponent_Factory(t) {
  return new (t || EditableGridComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.GridEditService), i0.ɵɵdirectiveInject(i3.ExportService), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.RememberStateService), i0.ɵɵdirectiveInject(i6.SharedService));
};
EditableGridComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: EditableGridComponent,
  selectors: [["app-editable-grid"]],
  inputs: {
    entityName: "entityName",
    editMode: "editMode",
    canAddNew: "canAddNew",
    canDelete: "canDelete",
    canEdit: "canEdit",
    sortable: "sortable",
    sort: "sort",
    selectable: "selectable",
    selectBy: "selectBy",
    selection: "selection",
    createParameters: "createParameters",
    compact: "compact",
    canEditRow: "canEditRow"
  },
  outputs: {
    selectionChange: "selectionChange",
    rowAction: "rowAction",
    save: "save",
    remove: "remove",
    add: "add"
  },
  features: [i0.ɵɵProvidersFeature([GridEditService]), i0.ɵɵInheritDefinitionFeature],
  decls: 6,
  vars: 23,
  consts: [["novalidate", ""], ["form", "ngForm"], [1, "editable", 3, "id", "data", "loading", "skip", "pageable", "pageSize", "sortable", "sort", "scrollable", "filterable", "filter", "groupable", "selectable", "kendoGridSelectBy", "height", "rowClass", "ngClass", "selectedKeys", "selectedKeysChange", "selectionChange", "selectAllChange", "pageChange", "dataStateChange", "edit", "save", "cancel", "remove", "add", "excelExport", "detailExpand", "detailCollapse"], [3, "width", 4, "ngIf"], [4, "ngIf"], [3, "width"], ["kendoGridCellTemplate", ""], ["kendoGridEditCommand", "", 1, "btn", "btn-xs", "btn-primary", 3, "disabled", "primary"], ["icon", "edit"], ["kendoGridSaveCommand", "", 1, "btn", "btn-xs", "btn-primary"], ["icon", "check", 4, "ngIf"], ["icon", "check"], ["kendoGridRemoveCommand", "", 1, "btn", "btn-xs", "btn-danger", 3, "disabled"], ["icon", "trash"], ["kendoGridCancelCommand", "", 1, "btn", "btn-xs", "btn-danger"], ["icon", "ban"], ["kendoGridToolbarTemplate", "", "position", "bottom"], ["class", "btn btn-sm btn-primary float-right", "type", "button", "kendoGridAddCommand", "", "translate", "", 4, "ngIf"], ["type", "button", "translate", "", 1, "btn", "btn-sm", "btn-primary", 3, "click"], ["type", "button", "kendoGridAddCommand", "", "translate", "", 1, "btn", "btn-sm", "btn-primary", "float-right"]],
  template: function EditableGridComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "form", 0, 1)(2, "kendo-grid", 2);
      i0.ɵɵlistener("selectedKeysChange", function EditableGridComponent_Template_kendo_grid_selectedKeysChange_2_listener($event) {
        return ctx.selection = $event;
      })("selectionChange", function EditableGridComponent_Template_kendo_grid_selectionChange_2_listener($event) {
        return ctx.onSelectionChange($event);
      })("selectAllChange", function EditableGridComponent_Template_kendo_grid_selectAllChange_2_listener($event) {
        return ctx.onSelectionChange($event);
      })("pageChange", function EditableGridComponent_Template_kendo_grid_pageChange_2_listener($event) {
        return ctx.onPageChange($event);
      })("dataStateChange", function EditableGridComponent_Template_kendo_grid_dataStateChange_2_listener($event) {
        return ctx.onDataStateChange($event);
      })("edit", function EditableGridComponent_Template_kendo_grid_edit_2_listener($event) {
        ctx.rowAction.emit();
        return ctx.gridEdit.editHandler($event, ctx.entityName);
      })("save", function EditableGridComponent_Template_kendo_grid_save_2_listener($event) {
        return ctx.onSave($event);
      })("cancel", function EditableGridComponent_Template_kendo_grid_cancel_2_listener($event) {
        return ctx.onCancel($event);
      })("remove", function EditableGridComponent_Template_kendo_grid_remove_2_listener($event) {
        return ctx.onRemove($event);
      })("add", function EditableGridComponent_Template_kendo_grid_add_2_listener($event) {
        return ctx.onAdd($event);
      })("excelExport", function EditableGridComponent_Template_kendo_grid_excelExport_2_listener($event) {
        return ctx.transformExcelExport($event);
      })("detailExpand", function EditableGridComponent_Template_kendo_grid_detailExpand_2_listener($event) {
        return ctx.onDetailExpand($event);
      })("detailCollapse", function EditableGridComponent_Template_kendo_grid_detailCollapse_2_listener($event) {
        return ctx.onDetailCollapse($event);
      });
      i0.ɵɵtemplate(3, EditableGridComponent_kendo_grid_command_column_3_Template, 2, 1, "kendo-grid-command-column", 3);
      i0.ɵɵtemplate(4, EditableGridComponent_kendo_grid_command_column_4_Template, 2, 1, "kendo-grid-command-column", 3);
      i0.ɵɵtemplate(5, EditableGridComponent_5_Template, 1, 0, null, 4);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("id", ctx.id);
      i0.ɵɵproperty("data", ctx.internalData)("loading", ctx.isBusy)("skip", ctx.state.skip)("pageable", ctx.pageable)("pageSize", ctx.state.take)("sortable", ctx.sortable)("sort", ctx.sort || ctx.state.sort)("scrollable", ctx.scrollable)("filterable", ctx.filterable)("filter", ctx.state.filter)("groupable", ctx.groupable)("selectable", ctx.selectable)("kendoGridSelectBy", ctx.selectBy)("height", ctx.height)("rowClass", ctx.rowClass)("ngClass", i0.ɵɵpureFunction1(21, _c0, ctx.compact))("selectedKeys", ctx.selection);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.editMode && ctx.canEdit);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.editMode);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", !!ctx.detailTemplate && ctx.dataLength > 0 || ctx.editMode && ctx.canAddNew);
    }
  },
  dependencies: [i7.NgClass, i7.NgIf, i8.ɵNgNoValidate, i8.NgControlStatusGroup, i8.NgForm, i9.FaIconComponent, i10.TranslateDirective, i11.GridComponent, i11.ToolbarTemplateDirective, i11.SelectionDirective, i11.FocusableDirective, i11.CommandColumnComponent, i11.CellTemplateDirective, i11.EditCommandDirective, i11.CancelCommandDirective, i11.SaveCommandDirective, i11.RemoveCommandDirective, i11.AddCommandDirective],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});