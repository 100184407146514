import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/1/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { VesselPermissions } from '@common/classes/permissions';
// import { VesselPermissions } from '@common/classes/permissions';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@progress/kendo-angular-grid";
import * as i9 from "@progress/kendo-angular-layout";
import * as i10 from "../../../../common/components/action-bar/action-bar.component";
import * as i11 from "../../../../common/components/app-control/app-control.component";
import * as i12 from "../../../../common/components/app-form/app-form.component";
import * as i13 from "../../../../common/components/content-header/content-header.component";
import * as i14 from "../../../../common/components/app-grid/app-grid.component";
import * as i15 from "../../../../common/components/app-control-container/app-control-container.component";
import * as i16 from "../../../../common/components/flag-icon.component";
import * as i17 from "../../../../common/components/accordion-header.component";
import * as i18 from "@ngx-translate/core";
import * as i19 from "../../../../common/pipes/codelist.pipe";
function VesselListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r8.numberOfActiveFilters, ")");
  }
}
function VesselListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 11);
    i0.ɵɵlistener("panelChange", function VesselListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 12);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, VesselListComponent_ng_template_6_span_5_Template, 2, 1, "span", 13);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r7 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r7);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function VesselListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 14);
    i0.ɵɵlistener("submit", function VesselListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r12 = i0.ɵɵnextContext();
      ctx_r12.search();
      return i0.ɵɵresetView(ctx_r12.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 15)(3, "div", 16)(4, "div", 17)(5, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function VesselListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.filter.vesselName = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 17)(8, "app-control", 19);
    i0.ɵɵlistener("ngModelChange", function VesselListComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.filter.typeCode = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(10, "div", 16)(11, "div", 17)(12, "app-control", 20);
    i0.ɵɵlistener("ngModelChange", function VesselListComponent_ng_template_7_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.filter.iMONumber = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 17)(15, "app-control", 21);
    i0.ɵɵlistener("ngModelChange", function VesselListComponent_ng_template_7_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.filter.statusId = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(17, "div", 22)(18, "div", 17)(19, "div", 23)(20, "button", 24);
    i0.ɵɵlistener("click", function VesselListComponent_ng_template_7_Template_button_click_20_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.clearFilter());
    });
    i0.ɵɵelement(21, "fa-icon", 25);
    i0.ɵɵtext(22, " \u00A0 ");
    i0.ɵɵelementStart(23, "span", 26);
    i0.ɵɵtext(24);
    i0.ɵɵpipe(25, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(26, "button", 27);
    i0.ɵɵlistener("click", function VesselListComponent_ng_template_7_Template_button_click_26_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r19 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r19.search());
    });
    i0.ɵɵelement(27, "fa-icon", 28);
    i0.ɵɵtext(28, " \u00A0 ");
    i0.ɵɵelementStart(29, "span", 26);
    i0.ɵɵtext(30);
    i0.ɵɵpipe(31, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(6, 16, "Vessel Name"));
    i0.ɵɵproperty("fetch", ctx_r1.fetchAllVesselNames)("fetchOnOpen", true)("ngModel", ctx_r1.filter.vesselName)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(9, 18, "Vessel Type"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.typeCode)("entity", ctx_r1.filter);
    i0.ɵɵadvance(4);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(13, 20, "IMO Number"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.iMONumber)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(16, 22, "Status"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.statusId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(25, 24, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(31, 26, "Search"));
  }
}
function VesselListComponent_ng_template_12_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function VesselListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, VesselListComponent_ng_template_12_ng_container_0_Template, 1, 0, "ng-container", 29);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r5 = i0.ɵɵreference(17);
    i0.ɵɵproperty("ngTemplateOutlet", _r5);
  }
}
function VesselListComponent_ng_template_15_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function VesselListComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, VesselListComponent_ng_template_15_ng_container_0_Template, 1, 0, "ng-container", 29);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r5 = i0.ɵɵreference(17);
    i0.ɵɵproperty("ngTemplateOutlet", _r5);
  }
}
const _c0 = function (a1, a2) {
  return ["/code-list/vessel", a1, a2];
};
function VesselListComponent_ng_template_16_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 40);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r28 = ctx.$implicit;
    const ctx_r22 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction2(2, _c0, ctx_r22.defaultViewMode, row_r28.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r28.name, " ");
  }
}
function VesselListComponent_ng_template_16_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r29 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r29.typeId, "VesselType")), " ");
  }
}
function VesselListComponent_ng_template_16_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-flag-icon", 41);
  }
  if (rf & 2) {
    const row_r30 = ctx.$implicit;
    i0.ɵɵproperty("code", row_r30.countryId)("floating", true);
  }
}
function VesselListComponent_ng_template_16_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "number");
  }
  if (rf & 2) {
    const row_r31 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind3(1, 1, row_r31.length, "1.2", "en"), " ");
  }
}
function VesselListComponent_ng_template_16_ng_template_19_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "number");
  }
  if (rf & 2) {
    const row_r32 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind3(1, 1, row_r32.grossTonnage, "1.3", "en"), " ");
  }
}
function VesselListComponent_ng_template_16_ng_template_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "number");
  }
  if (rf & 2) {
    const row_r33 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind3(1, 1, row_r33.netTonnage, "1.3", "en"), " ");
  }
}
function VesselListComponent_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    const _r35 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-grid", 30);
    i0.ɵɵlistener("sortChange", function VesselListComponent_ng_template_16_Template_app_grid_sortChange_0_listener($event) {
      i0.ɵɵrestoreView(_r35);
      const ctx_r34 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r34.onSortChange($event));
    });
    i0.ɵɵelementStart(1, "kendo-grid-column", 31);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵtemplate(3, VesselListComponent_ng_template_16_ng_template_3_Template, 2, 5, "ng-template", 32);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "kendo-grid-column", 33);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵtemplate(6, VesselListComponent_ng_template_16_ng_template_6_Template, 3, 6, "ng-template", 32);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(7, "kendo-grid-column", 34);
    i0.ɵɵpipe(8, "translate");
    i0.ɵɵelementStart(9, "kendo-grid-column", 35);
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵtemplate(11, VesselListComponent_ng_template_16_ng_template_11_Template, 1, 2, "ng-template", 32);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(12, "kendo-grid-column", 36);
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementStart(14, "kendo-grid-column", 37);
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵtemplate(16, VesselListComponent_ng_template_16_ng_template_16_Template, 2, 5, "ng-template", 32);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(17, "kendo-grid-column", 38);
    i0.ɵɵpipe(18, "translate");
    i0.ɵɵtemplate(19, VesselListComponent_ng_template_16_ng_template_19_Template, 2, 5, "ng-template", 32);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "kendo-grid-column", 39);
    i0.ɵɵpipe(21, "translate");
    i0.ɵɵtemplate(22, VesselListComponent_ng_template_16_ng_template_22_Template, 2, 5, "ng-template", 32);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵproperty("data", ctx_r6.query)("sortable", true)("sort", ctx_r6.gridSort);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 11, "Vessel Name"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 13, "Vessel Type"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 15, "IMO Number"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(10, 17, "Flag"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(13, 19, "Status"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(15, 21, "Length"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(18, 23, "Gross Tonnage"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(21, 25, "Net Tonnage"));
  }
}
export class VesselListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'Vessels';
    this.parentRoute = '/code-list/vessel';
    this.createPermission = VesselPermissions.Action.create;
    this.breadcrumb = [{
      icon: 'ship',
      title: this.translateService.get('Vessels')
    }];
    this.gridSort = [{
      field: 'name',
      dir: 'asc'
    }];
  }
  getDefaultFilter(data) {
    return this.entityManager.createEntity('GetVesselsQuery', {
      active: true,
      manual: this.activeTab === 1,
      ...data
    });
  }
  tabSelected(select) {
    var _superprop_getTabSelected = () => super.tabSelected,
      _this = this;
    return _asyncToGenerator(function* () {
      _this.filter.manual = select.index === 1;
      _superprop_getTabSelected().call(_this, select);
    })();
  }
  onSortChange(sort) {
    this.gridSort = sort;
  }
}
VesselListComponent.ɵfac = function VesselListComponent_Factory(t) {
  return new (t || VesselListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
VesselListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VesselListComponent,
  selectors: [["app-vessel-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 18,
  vars: 13,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "mt-3", 3, "tabSelect"], ["tabStrip", ""], [3, "title", "selected"], ["kendoTabContent", ""], ["tabContent", ""], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col"], ["type", "codelist", "property", "vesselName", 3, "label", "fetch", "fetchOnOpen", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "VesselType", "property", "typeCode", 3, "label", "ngModel", "entity", "ngModelChange"], ["onkeypress", "return event.charCode >= 48 && event.charCode <= 57", "property", "iMONumber", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "VesselStatus", "property", "statusId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], [4, "ngTemplateOutlet"], [1, "mb-2", 3, "data", "sortable", "sort", "sortChange"], ["field", "name", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "typeId", 3, "title"], ["field", "iMONumber", 3, "title"], ["field", "countryId", 3, "title"], ["field", "status.name", 3, "title"], ["field", "length", 3, "title"], ["field", "grossTonnage", 3, "title"], ["field", "netTonnage", 3, "title"], [1, "text-primary", "link-box", 3, "routerLink"], ["codelist", "Country", 3, "code", "floating"]],
  template: function VesselListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, VesselListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, VesselListComponent_ng_template_7_Template, 32, 28, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "kendo-tabstrip", 6, 7);
      i0.ɵɵlistener("tabSelect", function VesselListComponent_Template_kendo_tabstrip_tabSelect_8_listener($event) {
        return ctx.tabSelected($event);
      });
      i0.ɵɵelementStart(10, "kendo-tabstrip-tab", 8);
      i0.ɵɵpipe(11, "translate");
      i0.ɵɵtemplate(12, VesselListComponent_ng_template_12_Template, 1, 1, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(13, "kendo-tabstrip-tab", 8);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, VesselListComponent_ng_template_15_Template, 1, 1, "ng-template", 9);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(16, VesselListComponent_ng_template_16_Template, 23, 27, "ng-template", null, 10, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 7, "Vessels"));
      i0.ɵɵproperty("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(11, 9, "Vessels"))("selected", ctx.activeTab === 0);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(14, 11, "Manual Vessels"))("selected", ctx.activeTab === 1);
    }
  },
  dependencies: [i3.NgIf, i3.NgTemplateOutlet, i4.RouterLink, i5.NgControlStatus, i5.NgModel, i6.NgbAccordion, i6.NgbPanel, i6.NgbPanelContent, i6.NgbPanelHeader, i7.FaIconComponent, i8.ColumnComponent, i8.CellTemplateDirective, i9.TabStripComponent, i9.TabStripTabComponent, i9.TabContentDirective, i10.ActionBarComponent, i11.AppControlComponent, i12.AppFormComponent, i13.ContentHeaderComponent, i14.AppGridComponent, i15.AppControlContainerComponent, i16.FlagIconComponent, i17.AccordionHeaderComponent, i3.AsyncPipe, i3.DecimalPipe, i18.TranslatePipe, i19.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});